import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { Button as GatsbyButton } from 'gatsby-theme-material-ui';
import styled from 'styled-components';
import ButtonLink from '../button/buttonLink';
import Gallery from '../../utils/gallery';
import Icon from '../../utils/icon';

import { WindowDimensionsCtx } from '../../utils/windowDimensionsProvider';

const PanelContent = styled.div.attrs({
  className: 'panelContent',
})`
  ${(props) => props.theme.wrappersContainers.primaryWrapper};
`;

const PanelContainer = styled.div.attrs({
  className: 'panelContainer',
})`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  order: 1;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: white;
  overflow-y: scroll;
  transistion: all 0.2s ease-in-out 0s;
  opacity: ${(props) => (props?.isActive === true ? 1 : 0)};
  visibility: ${(props) => (props?.isActive === true ? 'visible' : 'hidden')};
  z-index: ${(props) => (props?.isActive === true ? 9 : 0)};
  pointer-events: ${(props) =>
    props?.isActive === true ? 'visible' : 'hidden'};
  transform: translateX(
    ${(props) => (props?.isActive === true ? '0px' : '-100vw')}
  );
  @media ${(props) => props.theme.mq.md} {
    height: auto;
    min-height: 90px;

    margin: 0 auto;
    width: 100%;
    position: absolute;
    transistion: unset;
    transform: unset;
    opacity: ${(props) => (props?.isActive === true ? 1 : 0)};
    visibility: ${(props) => (props?.isActive === true ? 'visible' : 'hidden')};
    z-index: ${(props) => (props?.isActive === true ? 9 : 0)};
    pointer-events: ${(props) =>
      props?.isActive === true ? 'visible' : 'hidden'};
    max-height: fit-content;
  }
`;
const PanelNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 1rem 1rem;

  @media ${(props) => props.theme.mq.md} {
    display: none;
  }

  button {
    position: relative;
    top: -4px;
  }
  svg {
    fill: ${(props) => props.theme.colors.secondary.main};
  }
`;

const MenuContainer = styled.div.attrs({
  className: 'panelMenuContainer',
})`
  position: relative;
  display: flex;
  width: 100%;
  animation: 0.2s slideInLeftAnimation;

  @media ${(props) => props.theme.mq.md} {
    padding: 2rem 0rem;
    animation: 0.2s fadeInAnimation linear 0s;
  }
  @keyframes slideInLeftAnimation {
    0% {
      opacity: 0;
      transform: translateX(-250px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
const PanelImage = styled.div.attrs({
  className: 'panelImage',
})`
  overflow: hidden;
  width: 384px;
  div {
    max-height: 100%;
  }

  .galleryWrapper {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
    align-items: start;

    &.gallery-1 {
      .item-0 {
        grid-area: 1/1/13/13;
      }
    }
  }
`;

const MenuItemList = styled.ul.attrs({
  className: 'panelMenuList',
})`
  list-style-type: none;
  display: grid;
  padding: 1rem 1rem;
  margin: 0;
  width: 100%;

  @media ${(props) => props.theme.mq.md} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    padding: 0;
  }
`;

const MenuItem = styled.li.attrs({
  className: 'panelMenuItem',
})`
  display: block;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  margin: 0rem;
  padding: 0.5rem 0;
  @media ${(props) => props.theme.mq.md} {
    padding: 0.5rem 0rem;
    margin: 0rem;
    border-bottom: revert;
  }
  /* a {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      color: ${(props) => props.theme.colors.secondary.main};
    }
  } */
`;

const MenuPanel = (props) => {
  const mediaContext = useContext(WindowDimensionsCtx);
  const menu = props?.panelData;
  const menuItems = menu?.childItems?.subMenu;
  const onClose = props.onClose;
  const pClass = props.className;

  const checkLink = (item) => {
    let link = '';

    const onlyPath = item.path;

    if (item !== null) {
      link =
        onlyPath.indexOf('http://') === 0 ||
        onlyPath.indexOf('https://') === 0 ||
        onlyPath.indexOf('mailto:') === 0 ? (
          <Button
            color='primary'
            size='small'
            variant='text'
            to={onlyPath}
            sx={{
              fontWeight: 300,
              fontSize: { xs: 12, md: 12, lg: 12.8 },
              p: { xs: 0 },
              justifyContent: 'flex-start',
            }}
          >
            {item.label}
          </Button>
        ) : (
          <GatsbyButton
            color='primary'
            size='small'
            onClick={(e) => optionClick(e, -1)}
            variant='text'
            to={onlyPath}
            sx={{
              fontWeight: 300,
              fontSize: { xs: 12, md: 12, lg: 12 },
              p: { xs: 0 },
              justifyContent: 'flex-start',
            }}
          >
            {item.label}
          </GatsbyButton>
        );
      return link;
    }

    return link;
  };

  const showMainPanelFeature = () => {
    const menuFeature = menu.menu_feature?.menuFeature;
    return (
      menuFeature?.showMenuFeature &&
      [mediaContext.width] > 1279 && (
        <PanelImage>
          <Gallery content={menuFeature} />
        </PanelImage>
      )
    );
  };

  const optionClick = (e, i) => {
    if (onClose) {
      onClose(true);
    }
  };

  const leave = (e, i) => {
    // if (e) {
    //   e.preventDefault()
    // }
    if (onClose) {
      onClose(false);
    }
  };

  if (!menu) return null;

  return (
    <PanelContainer
      className={pClass + ' active'}
      onMouseLeave={(e) => leave(e, -1)}
      {...props}
    >
      <PanelContent>
        <PanelNav>
          <ButtonLink onClick={(e) => leave(e, -1)}>
            <Icon name='cheveron-left' height='1.5rem' />
          </ButtonLink>
          <ButtonLink onClick={(e) => leave(e, -1)}>
            <Icon name='close' height='1rem' />
          </ButtonLink>
        </PanelNav>

        <MenuContainer>
          {showMainPanelFeature()}
          <MenuItemList>
            {menuItems?.map((item, key) => {
              return <MenuItem key={key}>{checkLink(item)}</MenuItem>;
            })}
          </MenuItemList>
        </MenuContainer>
      </PanelContent>
    </PanelContainer>
  );
};

export default MenuPanel;
